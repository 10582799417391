const $ = require('jquery')

const setProp = require('./setProperty')
const getResponsive = require('./getResponsiveCSSVar')


module.exports = function(){
	$("[data-match-from]").each(function(){
		var $item = $(this),
			prop = $item.data("match-prop"),
			from = $item.data("match-from"), $from,
			fromProp = $item.data("match-from-prop"),
			result

		if(!fromProp && prop) fromProp = prop

		if(from == "prev")
			$from = $item.prev()
		else if (from == "next")
			$from = $item.next()
		else if (from == "parent")
			$from = $item.parent()
		else
			$from = $(from)

		if($item.data("match-from-responsive") && !getResponsive($item, "match-from")){
			setProp($item, prop, null)
			return
		}

		if(fromProp == "outerHeight" || fromProp == "outerWidth")
			result = $from[fromProp]()
		else
			result = $from.css(fromProp)

		//console.log("match-from", $item, $from, prop, result)

		setProp($item, prop, result)
	})
}
