const $ = require('jquery')

const setProp = require('./setProperty')
const unround = require('./round').unround
const getResponsive = require('./getResponsiveCSSVar')

const DATA_HCENTER = "[data-hCenter]"

module.exports = function($element)
{
	if($element)
		$element = $($element).find(DATA_HCENTER)
	else
		$element = $(DATA_HCENTER)

	$element.each(function(){
		var $item = $(this),
			$parent = $item.parent(),
			prop = "left", result,
			useItemInner = $item.data("hcenter-inner"),
			useParentInner = $item.data("hcenter-parent-inner"),
			position = useParentInner || $item.data("hcenter-relative") ? "relative" : "absolute",
			getItemWidth = useItemInner ? "width" : "outerWidth",
			getParentWidth =  useParentInner ? "width" : "outerWidth",
			auto = $item.data("hcenter-auto")

		if($item.css("position") == "fixed") position = "fixed"

		// if this center is limited to a media query and it doesnt match, dont apply
		if(!getResponsive($item, "hcenter")) {
			$item.css({position: ""})
			setProp($item, prop, null)
			return
		}

		$item.css({position: position})
		unround($item, "width")

		var itemWidth = $item[getItemWidth](),
			parentWidth =  $parent[getParentWidth]()

		if(auto && parentWidth <= itemWidth){
			$item.css({position: "relative", width:"100%"})
			setProp($item, prop, 0)
			return
		}

		result = (parentWidth-itemWidth)/2
		setProp($item, prop, result)
	})
}
