const Barba = require('barba.js')
const $ = require('jquery')
const preloader = require('../utils/JS/preload')
const lazyLoad = require('../utils/JS/lazyLoad')


var FadeTransition = Barba.BaseTransition.extend({
	start: function() {
		Promise
		.all([this.newContainerLoading, this.fadeOut()])
		.then(this.preload.bind(this))
		.then(this.fadeIn.bind(this))
	},

	preload: function(){
		return preloader.load({element:this.newContainer})
	},

	fadeOut: function() {
		return $(this.oldContainer).animate({ opacity: 0 }).promise()
	},

	fadeIn: function() {
		var _this = this,
			$el = $(this.newContainer),
			$old = $(this.oldContainer)

		// adjust for previous pages scrollY
		$old.css({top: "-"+window.scrollY+"px"})
		$(window).scrollTop(0)

		$old.hide();
		$el.css({
			visibility : 'visible',
			opacity : 0
		})
		$el.animate({ opacity: 1 }, 400, function() {
			_this.done()
			lazyLoad($el)
		})
	}
})


Barba.Pjax.getTransition = function() { return FadeTransition }
