const $ = require('jquery')
const $window = $(window)

const setProp = require('./setProperty')
const unround = require('./round').unround
const getResponsive = require('./getResponsiveCSSVar')

const DATA_VCENTER = "[data-vCenter]"

module.exports = function($element)
{
	if($element)
		$element = $($element).find(DATA_VCENTER)
	else
		$element = $(DATA_VCENTER)

	var windowWidth = $window.width()

	$element.each(function(){
		var $item = $(this),
			$parent = $item.parent(),
			prop = "top", result,
			useItemInner = $item.data("vcenter-inner"),
			useParentInner = $item.data("vcenter-parent-inner"),
			position = useParentInner || $item.data("vcenter-relative") ? "relative" : "absolute",
			getItemHeight = useItemInner ? "height" : "outerHeight",
			getParentHeight =  useParentInner ? "height" : "outerHeight"

		if($item.css("position") == "fixed") position = "fixed"

		// if this center is limited to a media query and it doesnt match, dont apply
		if(!getResponsive($item, "vcenter")) {
			$item.css({position: ""})
			setProp($item, prop, null)
			return
		}

		$item.css({position: position})
		unround($item, "height")

		var	itemHeight = $item[getItemHeight](),
			parentHeight = $parent[getParentHeight]()

		if($item.data("vcenter-parent-auto")){
			$parent.css("height", "")
			parentHeight = $parent[getParentHeight]()
			if(parentHeight <= itemHeight){
				$parent.css("height", "auto")
				$item.css("position", "relative")
				setProp($item, prop, null)
				return
			} else {
				$item.css("position", "")
			}
		}

		result = (parentHeight-itemHeight)/2
		setProp($item, prop, result)
	})
}
