const Barba = require('barba.js')
const $ = require('jquery')

Barba.BaseView.extend({
	namespace: 'matter',
	onEnterCompleted: function() {

		var body = $('body'),
			overlay = $('#overlay'),
			message = $('#overlay-message'),
			cancel = $('.btn.cancel'),
			proceed = $('.btn.proceed'),
			content = $('.barba-container .content')

		//lock the Body from scrolling
		body.addClass('locked')
		content.addClass('blur')

		//show overlay & modal
		overlay.addClass('show')
		message.addClass('show')

		//close overlay on proceed
		proceed.on('click', function(){
			message.addClass('hide')
			overlay.removeClass('show')
			body.removeClass('locked')
			content.removeClass('blur')
			return false;
		})

		//go back on cancel
		cancel.on('click', function(){
			body.removeClass('locked')
			Barba.Pjax.goTo('/creditor-information/current-matters')
			return false;
		})


	},
	onLeave: function() {
		//
	}
}).init()
