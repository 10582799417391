const $ = require('jquery')

const RKT_CSS = "rkt.CSS."

function setProperty($item, p, v, options){
	var cssValue = getCSSValue(v),
	 	dataValue = getDataValue(v),
		fromData = options ? options.fromData : false

	if(fromData){
		cssValue = getCSSValue($item.data(RKT_CSS+p))
	} else {
		$item.data(RKT_CSS+p, dataValue)
	}

	$item.css(p, cssValue)
}

function getCSSValue(v){
	return v === null || v === false || v === undefined ? "" : v
}

function getDataValue(v){
	return v == "" || v === false ? null : v
}

module.exports = setProperty
