const Barba = require('barba.js')
const $ = require('jquery')
const preloader = require('../utils/JS/preload')
const mapJson = require('./map-styles.json')

Barba.BaseView.extend({
	namespace: 'contact',
	onEnter: function() {

		var map, marker, $map = $(this.container).find("#map"),
			address = $map.data("address"),
			geocoder = new google.maps.Geocoder(),
			zoom

		if ($(window).width() < 800) {
			zoom = 15
		}
		else {
			zoom = 17
		}

		geocoder.geocode({'address': address}, function(results, status) {
			if (status === 'OK') {
				map = new google.maps.Map($map[0], {
					zoom: zoom,
					disableDefaultUI: true,
					scrollWheel: true,
					scaleControl: true,
					draggable: true,
					center: results[0].geometry.location,
					styles: mapJson
				})
				var beachMarker = new google.maps.Marker({
					position: results[0].geometry.location,
					map: map,
					icon: $map.data("marker")
				})
			} else {
				console.log('Geocode was not successful for the following reason: ' + status)
			}
		})

	},
	onLeave: function() {
		//
	}
}).init()
