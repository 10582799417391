const Barba = require('barba.js')
const $ = require('jquery')
const List = require('list.js')

Barba.BaseView.extend({
	namespace: 'listing',
	onEnterCompleted: function() {

		var collectionList,
			options = {
				valueNames: [ 'title', 'date', 'timestamp', 'type' ],
				page: 10,
				pagination: true,
				outerWindow: 5
			};
		collectionList = new List('collection-list', options);

		$('.search-field i').on('click', function(){
			$(this).prev('input.search').focus()
		})

	},
	onLeave: function() {
		//
	}
}).init()
