export default () => ({
	experiences: false,
	filteredExperiences: false,
	searchTerm: "",
	industries: [],
	selectedIndustry: "all",
	experienceMatchesSearchTerm(experience) {
		return experience.title.toLowerCase().includes(this.searchTerm.toLowerCase())
			|| experience.snippet.toLowerCase().includes(this.searchTerm.toLowerCase())
			|| experience.the_situation.toLowerCase().includes(this.searchTerm.toLowerCase())
			|| experience.the_solution.toLowerCase().includes(this.searchTerm.toLowerCase())
			|| experience.the_outcome.toLowerCase().includes(this.searchTerm.toLowerCase());
	},
	experienceMatchesSelectedIndustry(experience) {
		if (this.selectedIndustry === "all") return true;
		return experience.industries.includes(this.selectedIndustry);
	},
	getIndustries(experience) {
		if (experience.industries.length === 0) return true; // return true if there are no industries
		return experience.industries.map(industryKey => this.industries.find(industry => industry.slug === industryKey).title).join(', ');
	},
	init() {
		fetch('/!/experiences/entries')
			.then(response => response.json())
			.then(data => { this.experiences = data; this.filteredExperiences = data; })
			.catch(error => console.error(error));
	},
});
