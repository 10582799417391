const Barba = require('barba.js')
const $ = require('jquery')
const preloadIsDone = require('../utils/JS/preload').isDone
import ScrollReveal from 'scrollreveal'

Barba.BaseView.extend({
	namespace: 'home',
	onEnter: function() {

		// $('header .logo').addClass('hide')

		var allAccordions = $('.trigger-accordion')
		allAccordions.not('.open').find('.accordion').hide()

		$('.trigger-accordion h2').on('mouseover', function(){
			if ($(this).parent().hasClass('open')) {
				$(this).parent().find('.accordion').slideUp()
				$(this).parent().removeClass('open')
			}
			else {
				allAccordions.siblings().find('.accordion').slideUp()
				$(this).parent().find('.accordion').slideDown()
				$(this).parent().addClass('open')
				$(this).parent().siblings().removeClass('open')
			}
			return false;
		})

	},
	onEnterCompleted: function() {

		// preloadIsDone().then(() => {
		// 	ScrollReveal().reveal('.page-logo img', { delay: 300, distance: '10px', reset: true });
		// 	ScrollReveal().reveal('.page-title', { delay: 700, distance: '10px', reset: true });
		// 	ScrollReveal().reveal('.feature-image', { delay: 500, distance: '10px', reset: true });
		//
		// 	ScrollReveal().reveal('.offset-image', { delay: 200, distance: '10px', reset: true });
		//
		// 	ScrollReveal().reveal('.news-post-1', { delay: 500, distance: '10px', reset: true });
		// 	ScrollReveal().reveal('.news-post-2', { delay: 600, distance: '10px', reset: true });
		//
		// })
	},
	onLeave: function() {

		// $('header .logo').removeClass('hide')

	}
}).init()
