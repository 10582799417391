const $ = require('jquery')
const Barba = require('barba.js')
require('preload-js')

const SRC = "src"
const LAZY_LOAD_SRC = "data-lazy-load-src"
const LAZY_LOAD_BG = "data-lazy-load-bg"
const LAZY_LOADING = "lazy-loading"

var loader, deferred

// main load function
function load(element){

	if(loader){
		loader.reset()
		loader.removeAll()
	}

	var $element = element ? $(element) : $("body"), src, needed = false,
		loader = new createjs.LoadQueue()

	loader.setMaxConnections(5)

	if(deferred) deferred.reject()
	deferred = Barba.Utils.deferred()

	// loop through all items and look for img and bg image data attributes
	$element.find("["+LAZY_LOAD_SRC+"], ["+LAZY_LOAD_BG+"]").each(function(){
		src = this.getAttribute(LAZY_LOAD_SRC) ? this.getAttribute(LAZY_LOAD_SRC) : this.getAttribute(LAZY_LOAD_BG)
		if(!src) return
		src = src.trim()
		if(src.length < 2) return
		loader.loadFile({src, data:{element:this}}, false)
		$(this).addClass(LAZY_LOADING)
		needed = true
	})

	if(!needed){
		deferred.resolve()
		return deferred.promise
	}

	loader.on("fileload", function(event){
		var item = event.item.data.element,
			$item = $(item).removeClass(LAZY_LOADING)

		if(item.hasAttribute(LAZY_LOAD_SRC))
			item.setAttribute("src", item.getAttribute(LAZY_LOAD_SRC))
		else if(item.hasAttribute(LAZY_LOAD_BG))
			$item.css("background-image", 'url("'+item.getAttribute(LAZY_LOAD_BG)+'")' )
	})

	loader.on("complete", function(){
		deferred.resolve()
	})

	loader.load()

	return deferred.promise
}

module.exports = load
