//const bugsnag = require('bugsnag-js');
//global.bugsnag = bugsnag(BUGSNAG_APIKEY);
// jQuery (defined as a global since so many plugins look for it in the global scope)
global.jQuery = require('jquery')

// global Barba
global.Barba = require('barba.js')

//alpinejs
import experiences from "./alpine/experiences.js";
import Alpine from 'alpinejs'
window.Alpine = Alpine
Alpine.data("experiences", experiences);
Alpine.start()

// rkt utils JS library
require('./utils/_rkt')
require('./css/_index')
require("regenerator-runtime/runtime");

// stop back button popping the scroll position
require('scroll-restoration-polyfill')
history.scrollRestoration = 'manual'

// Require js files in source/js here
require('./barba/transition.fade')
require('./barba/view.home')
require('./barba/view.matter')
require('./barba/view.listing')
require('./barba/view.contact')
require('./barba/view.asset')
require('./barba/view.staff')
require('./barba/view.print')
require('./main')
