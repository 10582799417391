const $ = require('jquery')

module.exports = function($form){
	$form.on("submit", function(event) {
		event.preventDefault()

		$form.find(".result").remove()
		var $button = $form.find("button")
		var data = $form.serialize()
		var url = $form.attr("action")
		$form.find("button, input, textarea").prop("disabled", true)
		$button.text("Submitting")

		$.ajax(url, {
			data: data,
			type: "POST",
			success: function(data){
				$button.text("Submit")
				$form.find("button, input, textarea").prop("disabled", false)
				var $success = $('<div>Success</div>').addClass("result")
				$form.append($success)
			},
			error: function(data){
				$button.text("Submit")
				$form.find("button, input, textarea").prop("disabled", false)
				var errors = data.responseJSON.errors
				var $errorList = $('<ul><li>' + errors.join('</li><li>') + '</li></ul>').addClass("result")
				$form.append($errorList)
			}
		})
	})
}
