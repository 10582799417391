const $ = require('jquery')

module.exports = function($item, v){
	var result = false,
		$item = $($item), item = $item.get(0)

	// if we are looking for a specific var and the current item is not set to be responsive, dont bother
	if(v && !$item.data(v+"-responsive")) return true

	if(window.getComputedStyle)
		result = window.getComputedStyle(item, ":before").getPropertyValue('content')
	else if(item.currentStyle)
		result = item.currentStyle['-content'] // for old IE

	result = String(result)

	if(v)
		return result.indexOf("rkt") > -1 && result.indexOf(v) > -1 ? true : false
	else
		return result.indexOf("rkt") > -1 ? result : false
}
