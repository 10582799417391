const $ = require('jquery')

var matches, $matches, built = false

const getProp = require('./getProperty')
const setProp = require('./setProperty')
const getResponsive = require('./getResponsiveCSSVar')

function init(){

	matches = {}
	$matches = $("[data-match]")

	$matches.each(function(i, e){
		var $item = $(this), group, prop,
			groupID = $item.data("match")

		if(!matches[groupID]) {
			matches[groupID] = {
				items:[],
				useChildren:[],
				func:"max",
				prop:"height",
				propFunc:"css",
				responsive: false,
				round: false,
				$ignore: false
			}
		}
		group = matches[groupID]
		group.items.push($item)
		group.useChildren.push( $item.data("match-children") ? true : false )

		prop = $item.data("match-prop")
		if(prop) group.prop = prop

		if($item.data("match-func")) group.func = $item.data("match-func")
		if(typeof group.func != "function"){
			if(group.func == "max")
				group.func = max
			else if(group.func == "min")
				group.func = min
			else if(group.func)
				group.func = window[group.func]
		}

		if($item.data("match-row")) group.row = true
		if($item.data("round")) group.round = true
		if($item.data("match-responsive")) group.responsive = $item

		if($item.data("match-ignore")){
			var ignore = $item.data("match-ignore").split(/ *, */)
			ignore = '[data-match="'+ignore.join('"], [data-match="')+'"]'
			group.$ignore = $(ignore)
		}
	})

	built = true
	update()
}

function update(){
	if(!built) {
		init()
		return
	}
	for(var groupID in matches){
		var group = matches[groupID],
			items = group.items,
			useChildren = group.useChildren,
			round = group.round,
			length = items.length,
			prop = group.prop,
			propFunc = group.propFunc,
			func = group.func,
			$item, itemProp, rows, itemsOffsets = {},
			offsetTop, offsetLeft, result, i, ignoreOriginalDisplay

		// if this group is meant to be responsive
		if(group.responsive && !getResponsive(group.responsive, "match")){
			for(i = 0; i < length; i++){
				setProp(items[i], prop, null)
			}
			continue
		}

		// if this group needs to hide other groups while doing the matching
		if(group.$ignore) {
			ignoreOriginalDisplay = group.$ignore.prop("style")["display"] || ""
			group.$ignore.css({display: "none"})
		}

		// get the first items prop as a starting point
		itemProp = getProp(items[0], prop, {children:useChildren[0], reset:true})
		result = itemProp

		// find the desired result
		for(i = 0; i < length; i++){
			$item = items[i]
			itemProp = getProp($item, prop, {children:useChildren[i], reset:true})
			result = func(result, itemProp)
		}

		if(round) result = Math.round(result)

		// apply the result
		for(i = 0; i<length; i++){
			$item = items[i]
			setProp($item, prop, result)
			// store new offsetTop in case we need it for rows
			offsetTop = Math.floor($item.offset().top)
			itemsOffsets[i] = offsetTop
		}

		// limit to current row?
		if(group.row)
		{
			rows = {}
			for(i = 0; i < length; i++){
				$item = items[i]
				itemProp = getProp($item, prop, {children:useChildren[i], reset:true})
				offsetTop = itemsOffsets[i]
				if(!rows[offsetTop]) rows[offsetTop] = itemProp
				rows[offsetTop] = func(rows[offsetTop], itemProp)
			}

			for(i = 0; i < length; i++){
				result = rows[itemsOffsets[i]]
				if(round) result = Math.round(result)
				setProp(items[i], prop, result)
			}
		}

		// restore ignored objects display
		if(group.$ignore) group.$ignore.css({display: ignoreOriginalDisplay})
	}
}

function max(a, b){
	return a > b ? a : b
}

function min(a, b){
	return a < b ? a : b
}

module.exports = {
	init: init,
	update: update
}
