const Barba = require('barba.js')
const $ = require('jquery')
const navController = require('./utils/JS/navController')
const setNavActive = require('./utils/JS/setNavActive').init
const preload = require('./utils/JS/preload')
const ProgressBar = require('progressbar.js')

const $document = $(document)
const $window = $(window)


$document.ready(() => {

	const bar = new ProgressBar.Line('#preload-bar', {easing: 'easeInOut'})
	const $bar = $("#preload-bar").css({display:"none"})
	var $overlay = $("#preload-overlay"),
		$searchTrigger = $('header .search, #search .search-close'),
		$searchBox = $('#search'),
		$searchInput = $("#search input"),
		searchActive = false

	preload.init({
		start: function(){
			bar.set(0.33)
			$bar.fadeIn(400)
		},
		progress: function(event){
			bar.set(0.3333+(event.progress*0.6666))
		},
		complete: function(){
			bar.set(1)
			$bar.fadeOut(400)
			if($overlay){
				$overlay.fadeOut(500, function() {
					$overlay = false
				})
			}
		}
	})

	Barba.Pjax.start()

	Barba.Dispatcher.on("newPageReady", function(c, p, html) {
		// global JS for every page
	})

	$(window).on('scroll', function(){
		if ($(window).scrollTop() > 60) {
			$('header').addClass('scrolled')
		}
		else {
			$('header').removeClass('scrolled')
		}
	})

	navController({
		trigger: ".nav-trigger",
		setActive: "header",
		autoClose: ".nav a.link",
		html: {
			".nav-trigger": "<i class='far fa-times'>"
		},
		breakPoint: 1440
	})

	setNavActive({
		"ul.nav li": {
			a: "> a",
			parents: { top: "ul.nav", element:"li" }
		}
	})

	$searchTrigger.on('click', function(){
		if (searchActive == false) {
			$searchBox.addClass('active')
			$searchInput.focus()
			searchActive = true
			$('body').addClass('locked')
		}
		else {
			$searchBox.removeClass('active')
			$('body').removeClass('locked')
			searchActive = false
		}
		return false
	})

	$(window).on('scroll', function(){
		if ($(window).scrollTop() > $(window).height()) {
			$('.rtt').addClass('active')
		}
		else {
			$('.rtt').removeClass('active')
		}
	})

	// Handle Third Nav
	$('.third-nav').each(function() {
		var $this = $(this)
		var parent = $(this).parent()
		var trigger = parent.children('i:first')

		trigger.click(function(e) {
			$(this).toggleClass('fa-chevron-right')
			$(this).toggleClass('fa-chevron-down')

			$this.toggleClass('show')
		})
	});

	$('li.has_children > a').on('click', function(){
		$(this).parent().siblings().removeClass('hovered')
		$(this).parent().toggleClass('hovered')
		return false
	})

	$('body').on('click', function(){
		$('li.has_children').removeClass('hovered')
	})


})
