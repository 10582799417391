/* CSS Helper functions for pure CSS and EQCSS */
const Barba = require('barba.js')
const $ = require('jquery')
const $window = $(window)

// main CSS/JS functions
const CSS = require('./_CSS')
const JS = require('./_JS')

// preloader
const preload = require('./JS/preload')
preload.rkt(init)

// vars
var resizeTimeout


// window resize
$window.on('resize', function(){
	clearTimeout(resizeTimeout)
	resizeTimeout = setTimeout(update, 50)
})

function update(){
	CSS.match.update()
	always()
}

function init(){
	CSS.match.init()
	always()
	JS.setNavActive.update()
}

function always(){
	CSS.matchFrom()
	CSS.eval()
	CSS.horizontalCenter()
	CSS.verticalCenter()
	CSS.round()
}

// barba page load
Barba.Dispatcher.on('newPageReady', function(oldState, newState, newHTML){
	if(!preload.initialPreloadDone()) {
		JS.preload.load()
		.then(JS.lazyLoad)
	}

	// google analytics tracking if it exists
	if (typeof ga === 'function')  ga('send', 'pageview', location.pathname)
})

// global vars/exports
global.rkt = module.exports = {
	CSS: CSS,
	JS: JS
}
