const $ = require('jquery')
const RKT_CSS = "rkt.CSS."

// options = children, reset, fromData, dataOnly
function getProperty($item, prop, options){
	var propertyFunction = "css", p = prop, result,
		children = options.children, reset = options.reset,
		dataOnly = options.dataOnly, fromData = options.fromData

	if(children){
		if(prop == "height") prop = "outerHeight"
		if(prop == "width") prop = "outerWidth"
	}

	if(prop == "outerHeight" || prop == "outerWidth") {
		propertyFunction = prop
		prop = children ? true : false
	}

	if(reset) $item.css(p, "")

	if(children){
		var total = false
		$item.children().each(function(){
			result = parseFloat($(this)[propertyFunction](prop))
			total = total ? total + result : result
		})
		return total
	}
	else
	{
		if(dataOnly)
			return $item.data(RKT_CSS+prop)
		else if(fromData && $item.data(RKT_CSS+prop))
			return $item.data(RKT_CSS+prop)
		else
			return parseFloat($item[propertyFunction](prop))
	}
}

module.exports = getProperty
