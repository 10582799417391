const $ = require('jquery')

const ROUND = "rkt.CSS.round."
const RKT_CSS = "rkt.CSS."
const getProp = require('./getProperty')
const setProp = require('./setProperty')
const getResponsive = require('./getResponsiveCSSVar')

function round(){
	$("[data-round]").each(function(){
		var $item = $(this), i, datas = $item.data(), data,
			propVal, prop = $item.data("round"),
			props = prop == "data-round" || prop == "" ? [] : prop.split(",")

		if($item.data("round-responsive") && !getResponsive($item, "round"))
			unround($item, false, datas)

		// if needed round all CSS set by rkt
		if(prop == "data-round" || prop == "" || prop.indexOf("rkt") > -1){
			for(data in datas){
				if(datas[data] && data.indexOf(RKT_CSS) === 0) props.push(data.split(RKT_CSS)[1])
			}
		}

		// apply rounding for all target properties
		for(i = 0; i < props.length; i++){
			prop = props[i]
			propVal = parseFloat(getProp($item, prop, {reset:true, fromData:true}))
			$item.css(prop, Math.round(propVal))
			$item.data(ROUND+prop, propVal)
		}
	})
}

function unround($item, prop, datas){
	var props = {}, data
	if(prop) props[ROUND+prop] = true
	if(datas){
		for(data in datas){
			if(datas[data] && data.indexOf(ROUND) === 0) props[datas[data]] = true
		}
	}
	for(prop in props){
		setProp($item, prop, null, {fromData:true})
	}
}

module.exports = {
	round: round,
	unround: unround,
	ROUND: ROUND
}
