const Barba = require('barba.js')
const $ = require('jquery')

Barba.BaseView.extend({
	namespace: 'print',
	onEnterCompleted: function() {

		setTimeout(function(){
			window.print();
		},1000)

	}
}).init()
