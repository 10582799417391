const $ = require('jquery')

const setProp = require('./setProperty')
const getProp = require('./getProperty')
const getResponsive = require('./getResponsiveCSSVar')

module.exports = function(){
	$("[data-eval]").each(function(){
		var $item = $(this),
			sourceString = $item.data("eval"),
			targetStrings = sourceString.split(/ *, */),
			str, i, match, prop, originalVal, val, result, jq, $jq, css,
			jqRegex = /([^\d\s]+|this)\.([A-Za-z\-]+)/g,
			cssRegex = /\d+[px|%|vh|vw|em|rem|pt|pc|ex]+/g

			for(str in targetStrings){

				match = targetStrings[str].match(/^([^ :]+) *:(.*)/)
				if(!match) continue

				prop = match[1]
				val = originalVal = match[2]

				// check if its responsive
				if($item.data("eval-responsive") && !getResponsive($item, "eval")){
					setProp($item, prop, null)
					continue
				}

				// look for jquery objects
				jq = jqRegex.exec(originalVal)
				while(jq != null){
					if(jq[1] == "this")
						result = getProp($item, jq[2], {})
					else {
						$jq = $(jq[1])
						if($jq.length)
							result = getProp($jq, jq[2], {})
						else
							result = ""
					}
					val = val.replace(jq[0], result)
					jq = jqRegex.exec(originalVal)
				}

				// look for CSS values
				css = cssRegex.exec(originalVal)
				while(css != null){
					$item.css(prop, css[0])
					result = $item.css(prop)
					val = val.replace(css[0], result)
					css = cssRegex.exec(originalVal)
				}

				// strip out px and evaluate/apply
				val = val.replace(/px/g, "")
				val = eval(val)
				setProp($item, prop, eval(val))
			}
	})
}
