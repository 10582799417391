const Barba = require('barba.js')
const $ = require('jquery')
const preloadIsDone = require('../utils/JS/preload').isDone
import ScrollReveal from 'scrollreveal'

Barba.BaseView.extend({
	namespace: 'staff',
	onEnterCompleted: function() {

		// preloadIsDone().then(() => {
		// 	ScrollReveal().reveal('.page-title', { delay: 300, distance: '10px', reset: true });
		// 	ScrollReveal().reveal('.staff-details', { delay: 600, distance: '10px', reset: true });
		// 	ScrollReveal().reveal('.feature-image', { delay: 500, distance: '30px', reset: true });
		// })
	},
	onLeave: function() {
		//
	}
}).init()
