/* common CSS utility JS goes here */
module.exports = {

	/** Center each item vertically based on parent container height */
	verticalCenter: require('./CSS/verticalCenter'),

	/** Center each item horizontally based on parent container height */
	horizontalCenter: require('./CSS/horizontalCenter'),

	/** Match heights of elements based on their data-col attribute */
	match: require('./CSS/match'),

	matchFrom: require('./CSS/matchFrom'),

	eval: require('./CSS/eval'),

	round: require('./CSS/round').round,
	unround: require('./CSS/round').unround,

	getProperty: require('./CSS/getProperty'),
	setProperty: require('./CSS/setProperty')
}
