/* common utility JS goes here */
module.exports = {

	setNavActive: require('./JS/setNavActive'),

	navController: require('./JS/navController'),

	formAjax: require('./JS/formAjax'),

	preload: require('./JS/preload'),

	lazyLoad: require('./JS/lazyLoad')
}
