const Barba = require('barba.js')
const $ = require('jquery')
const magnificPopup = require('magnific-popup')

Barba.BaseView.extend({
	namespace: 'asset',
	onEnter: function() {

		$('.gallery').magnificPopup({
			delegate: 'a', // child items selector, by clicking on it popup will open
			type: 'image'
			// other options
		});

	},
	onLeave: function() {

		// $('header .logo').removeClass('hide')

	}
}).init()
